import React from "react"
import PropTypes from "prop-types"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useDispatch, useSelector } from "react-redux"
import { FaSpinner, FaWhatsapp } from "react-icons/fa"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import usePrice from "components/hooks/usePrice"
import { useCallToAction } from "components/hooks/useCallToAction"
import { useHistory } from "react-router-dom"
import useWindow from "components/hooks/useWindow"
import { Markup } from "interweave"
import LazyLoad from "react-lazyload"
import { Link } from "react-router-dom"

function WideContent({ data }) {
  console.log(data)
  const dispatch = useDispatch()
  const history = useHistory()
  const { isMobile } = useWindow()

  const { layoutPages, homePage, addOrRemoveLoad } = useSelector(state => ({
    layoutPages: state.LayoutPages.layoutPages,
    homePage: state.LayoutPages.homePage,
    addOrRemoveLoad: state.Cart.addOrRemoveLoad,
  }))

  const handlePageProductData = () => {
    if (location.pathname === "/") {
      // add another check if needed
      return homePage?.product
    } else return layoutPages?.product
  }
  const product = handlePageProductData()

  console.log(product)

  const { stock } = usePrice(product)

  const handleCTA = type =>
    useCallToAction(product, dispatch, type, stock, history, data?.link)

  const web = (
    <div
      className={`wideContent  h-100 ${
        data?.images && data?.images[0]?.url && data?.imagePosition
      }`}
      style={{ backgroundColor: data?.bgColor }}
    >
      <div
        className={`content  ${
          data?.images && data?.images[0]?.url && "withImg"
        }`}
        style={{
          textAlign: data?.textAlign,
          alignItems: data?.textAlign,
        }}
      >
        {data?.title && (
          <h4
            className="section-head "
            style={{
              color: data?.fgColor,
              textAlign: data?.textAlign,
              fontWeight: "bold",
            }}
          >
            {data?.title}
          </h4>
        )}
        {data?.description && <Markup content={data?.description} />}

        {data?.callToAction?.isRequired && data?.callToAction?.text && (
          <>
            {data?.callToAction?.isRequired === true && (
              <button
                className="btn d-flex align-items-center justify-content-center"
                onClick={() => handleCTA(data?.callToAction?.action)}
              >
                {data?.callToAction?.text}
                {addOrRemoveLoad && <FaSpinner className="mx-2 spinner" />}
              </button>
            )}
            {data?.callToAction?.type === "link" && (
              <div className="d-flex justify-content-center">
                <Link
                  to={data?.link}
                  style={
                    {
                      // textDecoration: "underline",
                      // color: "#41655b",
                    }
                  }
                  className="mt-3 d-flex align-items-center justify-content-center btn"
                  onClick={() => handleCTA(data?.callToAction?.action)}
                >
                  {data?.callToAction?.text}
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      {data?.images?.length >= 1 && (
        <div className="contentImage">
          <LazyLoad height={200}>
            <img
              src={useCloudinary(
                data?.images[0]?.url ?? "",
                isMobile ? "w_400" : "w_800,q_70"
              )}
              height={"100%"}
              width={"100%"}
              alt="wide-content-image"
              loading="lazy"
            />
          </LazyLoad>
        </div>
      )}
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default WideContent

WideContent.propTypes = {
  data: PropTypes.object,
}
