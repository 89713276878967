/* USERS CART */
export const GET_USER_CART = "GET_USER_CART"
export const GET_USER_CART_SUCCESS = "GET_USER_CART_SUCCESS"
export const GET_USER_CART_FAIL = "GET_USER_CART_FAIL"

export const GET_LOCAL_CART = "GET_LOCAL_CART"
export const GET_LOCAL_CART_SUCCESS = "GET_LOCAL_CART_SUCCESS"
export const GET_LOCAL_CART_FAIL = "GET_LOCAL_CART_FAIL"

export const BULK_CART = "BULK_CART"
export const BULK_CART_ADDED = "BULK_CART_ADDED"
export const BULK_CART_FAIL = "BULK_CART_FAIL"

export const ADD_OR_REMOVE = "ADD_OR_REMOVE"
export const ADD_OR_REMOVE_SUCCESS = "ADD_OR_REMOVE_SUCCESS"
export const ADD_OR_REMOVE_FAIL = "ADD_OR_REMOVE_FAIL"

//coupon
export const COUPON_VALIDATE = "COUPON_VALIDATE"
export const COUPON_VALIDATE_SUCCESS = "COUPON_VALIDATE_SUCCESS"
export const COUPON_VALIDATE_FAIL = "COUPON_VALIDATE_FAIL"

//order
export const GET_USER_ORDERS = "GET_USER_ORDERS"
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS"
export const GET_USER_ORDERS_FAIL = "GET_USER_ORDERS_FAIL"

export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS"
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS"
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL"

export const MAKE_ORDER = "MAKE_ORDER"
export const MAKE_ORDER_SUCCESS = "MAKE_ORDER_SUCCESS"
export const MAKE_ORDER_FAIL = "MAKE_ORDER_FAIL"

export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

export const RE_ORDER = "RE_ORDER"
export const RE_ORDER_SUCCESS = "RE_ORDER_SUCCESS"
export const RE_ORDER_FAIL = "RE_ORDER_FAIL"

export const CANCEL_ORDER = "CANCEL_ORDER"
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS"
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL"

export const RETURN_ORDER = "RETURN_ORDER"
export const RETURN_ORDER_SUCCESS = "RETURN_ORDER_SUCCESS"
export const RETURN_ORDER_FAIL = "RETURN_ORDER_FAIL"

//payment
export const CREATE_RAZORPAY_ORDER = "CREATE_RAZORPAY_ORDER"
export const CREATE_RAZORPAY_ORDER_SUCCESS = "CREATE_RAZORPAY_ORDER_SUCCESS"
export const CREATE_RAZORPAY_ORDER_FAIL = "CREATE_RAZORPAY_ORDER_FAIL"

export const VERIFY_RAZORPAY_ORDER = "VERIFY_RAZORPAY_ORDER"
export const VERIFY_RAZORPAY_ORDER_SUCCESS = "VERIFY_RAZORPAY_ORDER_SUCCESS"
export const VERIFY_RAZORPAY_ORDER_FAIL = "VERIFY_RAZORPAY_ORDER_FAIL"

//address
export const GET_USER_ADDRESS = "GET_USER_ADDRESS"
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS"
export const GET_USER_ADDRESS_FAIL = "GET_USER_ADDRESS_FAIL"

export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS"
export const UPDATE_USER_ADDRESS_SUCCESS = "UPDATE_USER_ADDRESS_SUCCESS"
export const UPDATE_USER_ADDRESS_FAIL = "UPDATE_USER_ADDRESS_FAIL"

export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS"
export const DELETE_USER_ADDRESS_SUCCESS = "DELETE_USER_ADDRESS_SUCCESS"
export const DELETE_USER_ADDRESS_FAIL = "DELETE_USER_ADDRESS_FAIL"

export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS"
export const SET_DEFAULT_ADDRESS_SUCCESS = "SET_DEFAULT_ADDRESS_SUCCESS"
export const SET_DEFAULT_ADDRESS_FAIL = "SET_DEFAULT_ADDRESS_FAIL"

//favorite
export const GET_FAVORITE_ITEMS = "GET_FAVORITE_ITEMS"
export const GET_FAVORITE_ITEMS_SUCCESS = "GET_FAVORITE_ITEMS_SUCCESS"
export const GET_FAVORITE_ITEMS_FAIL = "GET_FAVORITE_ITEMS_FAIL"

export const ADD_OR_REMOVE_FAV = "ADD_OR_REMOVE_FAV"
export const ADD_OR_REMOVE_FAV_SUCCESS = "ADD_OR_REMOVE_FAV_SUCCESS"
export const ADD_OR_REMOVE_FAV_FAIL = "ADD_OR_REMOVE_FAV_FAIL"

//quick purchase
export const GET_QUICKLIST_ITEMS = "GET_QUICKLIST_ITEMS"
export const GET_QUICKLIST_ITEMS_SUCCESS = "GET_QUICKLIST_ITEMS_SUCCESS"
export const GET_QUICKLIST_ITEMS_FAIL = "GET_QUICKLIST_ITEMS_FAIL"

export const PAYMENT_CLEAR_ORDER_STATE = "PAYMENT_CLEAR_ORDER_STATE"
export const CLEAR_ORDER_STATE = "CLEAR_ORDER_STATE"
export const CLEAR_FULL_ORDER_STATE = "CLEAR_FULL_ORDER_STATE"

export const CREATE_EARNING_FROM_ORDER = "CREATE_EARNING_FROM_ORDER"
export const CREATE_EARNING_FROM_ORDER_SUCCESS =
  "CREATE_EARNING_FROM_ORDER_SUCCESS"
export const CREATE_EARNING_FROM_ORDER_FAIL = "CREATE_EARNING_FROM_ORDER_FAIL"

//open payments
export const GET_OPEN_PAYMENT_DETAILS_ORDER = "GET_OPEN_PAYMENT_DETAILS_ORDER"
export const GET_OPEN_PAYMENT_DETAILS_ORDER_SUCCESS =
  "GET_OPEN_PAYMENT_DETAILS_ORDER_SUCCESS"
export const GET_OPEN_PAYMENT_DETAILS_ORDER_FAIL =
  "GET_OPEN_PAYMENT_DETAILS_ORDER_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"

export const CLEAR_SERVER_CART = "CLEAR_SERVER_CART"

// phone pay
export const CREATE_PHONEPE_ORDER = "CREATE_PHONEPE_ORDER"
export const CREATE_PHONEPE_ORDER_SUCCESS = "CREATE_PHONEPE_ORDER_SUCCESS"
export const CREATE_PHONEPE_ORDER_FAIL = "CREATE_PHONEPE_ORDER_FAIL"

export const VERIFY_PHONEPE_ORDER = "VERIFY_PHONEPE_ORDER"
export const VERIFY_PHONEPE_ORDER_SUCCESS = "VERIFY_PHONEPE_ORDER_SUCCESS"
export const VERIFY_PHONEPE_ORDER_FAIL = "VERIFY_PHONEPE_ORDER_FAIL"

export const CHAIN_PROMOTER_GENERATE = "CHAIN_PROMOTER_GENERATE"
export const CHAIN_PROMOTER_GENERATE_SUCCESS = "CHAIN_PROMOTER_GENERATE_SUCCESS"
export const CHAIN_PROMOTER_GENERATE_FAIL = "CHAIN_PROMOTER_GENERATE_FAIL"

// ima wallet
// /payment/ima-wallet/order/652a73485d7c8166b5342d5

export const CREATE_IMA_PAYMENT = "CREATE_IMA_PAYMENT"
export const CREATE_IMA_PAYMENT_SUCCESS = "CREATE_IMA_PAYMENT_SUCCESS"
export const CREATE_IMA_PAYMENT_FAIL = "CREATE_IMA_PAYMENT_FAIL"

export const IMA_OTP_VERIFY = "IMA_OTP_VERIFY"
export const IMA_OTP_VERIFY_SUCCESS = "IMA_OTP_VERIFY_SUCCESS"
export const IMA_OTP_VERIFY_FAIL = "IMA_OTP_VERIFY_FAIL"

//course
export const COURSE_REGISTRATION = "COURSE_REGISTRATION"
export const COURSE_REGISTRATION_SUCCESS = "COURSE_REGISTRATION_SUCCESS"
export const COURSE_REGISTRATION_FAIL = "COURSE_REGISTRATION_FAIL"

export const GET_COURSE_PROVIDER_DETAILS = "GET_COURSE_PROVIDER_DETAILS"
export const GET_COURSE_PROVIDER_DETAILS_SUCCESS =
  "GET_COURSE_PROVIDER_DETAILS_SUCCESS"
export const GET_COURSE_PROVIDER_DETAILS_FAIL =
  "GET_COURSE_PROVIDER_DETAILS_FAIL"
