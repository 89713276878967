import React from "react"

import LazyLoad from "react-lazyload"
import loadingDf from "../../assets/imgs/theme/loadingDf.gif"
import { companyName } from "project.config"

const Preloader = () => {
  return (
    <>
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center">
              <LazyLoad height={50}>
                <img
                  src={loadingDf}
                  alt={companyName}
                  height={"140px"}
                  className="p-1 tada-animation"
                  width={140}
                  title="Loading..."
                  loading="eager"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Preloader
