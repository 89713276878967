import React from "react"
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"
import useCloudinary from "components/hooks/useCloudinaryImage"
import useWindow from "components/hooks/useWindow"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"
import { companyName } from "project.config"

SwiperCore.use([Navigation, Autoplay, Pagination])

function Carousel({ data, className }) {
  const { width } = useWindowSize()
  const { isMobile } = useWindow()
  const history = useHistory()

  const banners = data?.images

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return data?.carouselCount
    } else if (width < 640) {
      return data?.carouselCount
    } else if (width < 768) {
      return data?.carouselCount
    } else return data?.carouselCount
  }

  // const handlePreviewCount = maxCount => {
  //   if (banners?.length < maxCount) {
  //     return banners?.length
  //   } else return maxCount
  // }

  const handleRedirectLink = index => {
    if (data?.link) {
      return history.push(data?.link)
    } else if (data?.imageLinks?.length >= 0) {
      return history.push(data?.imageLinks[index])
    }
    return null
  }

  const web = (
    <div className={"mobileCarousel " + " row " + className}>
      <div className="col-12">
        {data?.title && (
          <h2
            className={`section-head mt-50 ${!data?.description && "mb-20"} `}
            style={{ textAlign: data?.textAlign }}
          >
            {data?.title}
            {data?.description && (
              <p className="section-description mb-20">{data?.description}</p>
            )}
          </h2>
        )}

        <div
          className="row related-products position-relative"
          style={{
            paddingTop: data?.carousel?.carouselPadding[0] || 0,
            paddingRight: data?.carousel?.carouselPadding[1] || 0,
            paddingBottom: data?.carousel?.carouselPadding[2] || 0,
            paddingLeft: data?.carousel?.carouselPadding[3] || 0,
          }}
        >
          <Swiper
            slidesPerView={handleWindowSizeVsSliderPreview()}
            spaceBetween={10}
            grid={{
              rows: 2,
            }}
            // pagination={
            //   data?.carousel?.paginationDot
            //     ? data?.carousel?.paginationDot
            //     : false
            // }
            pagination={true}
            // navigation={
            //   data?.carousel?.navigationButton
            //     ? data?.carousel?.navigationButton
            //     : false
            // }
            navigation={true}
            keyboard={{ enabled: true }}
            direction="horizontal"
            autoplay
            // speed={600 * 1000}
            className=""
            // breakpoints={{
            //   480: {
            //     slidesPerView: data?.carousel?.carouselCount,
            //   },
            //   640: {
            //     slidesPerView: data?.carousel?.carouselCount,
            //   },
            //   768: {
            //     slidesPerView: data?.carousel?.carouselCount,
            //   },
            //   1024: {
            //     slidesPerView: data?.carousel?.carouselCount,
            //   },
            // }}
          >
            {banners?.map((item, i) => (
              <SwiperSlide key={i}>
                <div
                  style={{
                    borderRadius: 0,
                    maxHeight: data?.height + "vh",
                    height: data?.height
                      ? data?.height + "vh"
                      : isMobile
                      ? "auto"
                      : "310px",
                  }}
                  className="banner-img mobileContainer wow animate__animated animate__fadeInUp  w-100"
                  onClick={() => handleRedirectLink(i)}
                >
                  <span className="cursor-pointer">
                    <LazyLoad height={200}>
                      <img
                        src={useCloudinary(
                          item?.url ?? "",
                          isMobile ? "w_980" : "w_1900,q_70"
                        )}
                        alt={`${companyName}-bannerImg`}
                        className="fit-cover h-100 w-100 object-start mobileBanner "
                        style={{ borderRadius: 0 }}
                        height={"100%"}
                        width={"100%"}
                        title={`${companyName}-bannerImg`}
                        loading="eager"
                      />
                    </LazyLoad>
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default Carousel

Carousel.propTypes = {
  data: PropTypes.any,
  className: PropTypes.string,
}
