import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"
import { Button, Col, Row } from "reactstrap"
import { map } from "lodash"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

SwiperCore.use([Navigation])

function ProductVariant({ data }) {
  const history = useHistory()
  const { width } = useWindowSize()

  const gridSize = data?.gridSize
  const relatedProd = data?.productVariants

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 2.5
    } else return 5.5
  }

  const handleProductSpace = () => {
    if (width < 480) {
      return 15
    } else return 20
  }

  return (
    <>
      {data?.product_variant_layout === "carousel" ? (
        <div
          className="row px-4 py-5 product-varient related-product-wrap "
          style={{ height: width < 480 && 320 }}
        >
          <div className="col-12">
            {data?.title && (
              <h2
                className="section-head"
                style={{ textAlign: data?.textAlign }}
              >
                {data?.title}
              </h2>
            )}
            {data?.description && (
              <p className="section-description">{data?.description}</p>
            )}
            <div className="row related-products position-relative pages-product-varient">
              <>
                <Swiper
                  initialSlide={0}
                  centeredSlides={false}
                  freeMode={true}
                  loop={false}
                  spaceBetween={handleProductSpace()}
                  slidesPerView={handleWindowSizeVsSliderPreview()}
                  grid={{
                    rows: 2,
                  }}
                  keyboard={{ enabled: true }}
                  direction="horizontal"
                  navigation={{
                    prevEl: ".custom_prev_n",
                    nextEl: ".custom_next_n",
                  }}
                  autoplay={false}
                  speed={800}
                  className=""
                  breakpoints={{
                    // 320: {
                    //   slidesPerView: 1.5,
                    // },
                    480: {
                      slidesPerView: 1.5,
                    },
                    640: {
                      slidesPerView: 2.5,
                    },
                    768: {
                      slidesPerView: 3.5,
                    },
                    1024: {
                      slidesPerView: 5.5,
                    },
                  }}
                >
                  {relatedProd?.map((product, i) => (
                    <SwiperSlide key={i}>
                      <ProductCardOne product={product} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="slider-arrow slider-arrow-2 carausel-6-columns-arrow">
                  <span className="slider-btn slider-prev slick-arrow custom_prev_n">
                    <i className="fi-rs-angle-left"></i>
                  </span>
                  <span className="slider-btn slider-next slick-arrow custom_next_n">
                    <i className="fi-rs-angle-right"></i>
                  </span>
                </div>
              </>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {/* <MeltingButton link={"/products"} text={"VIEW ALL PRODUCTS"} /> */}
              {/* <Link to="/products">
                <Button>VIEW ALL PRODUCTS</Button>
              </Link> */}
              {data?.callToAction?.type === "link" && (
                <div className="d-flex justify-content-center">
                  <Link
                    to={data?.link}
                    style={
                      {
                        // textDecoration: "underline",
                        // color: "#41655b",
                      }
                    }
                    className="mt-3 d-flex align-items-center justify-content-center btn"
                    onClick={() => handleCTA(data?.callToAction?.action)}
                  >
                    {data?.callToAction?.text}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Row className="mt-30 px-4 justify-content-center">
          <div
            className="m-0 p-1 "
            style={{ fontSize: width < 480 ? 25 : 40, fontWeight: 600 }}
          >
            {data?.title && (
              <div
                className="section-head font-size-18"
                style={{ textAlign: data?.textAlign, padding: 5 }}
              >
                {data?.title}
              </div>
            )}
          </div>
          {map(data?.productVariants, (product, key) => (
            <Col
              className="p-0 px-1"
              lg={12 / gridSize?.lg}
              xs={12 / gridSize?.sm}
              key={key}
              // onClick={() => (data?.link ? history.push(`${data?.link}`) : "")}
              style={{ cursor: data?.link ? "pointer" : "default" }}
            >
              <div key="d" className="gridItem">
                <ProductCardOne product={product} />
              </div>
            </Col>
          ))}
          <div className="d-flex align-items-center justify-content-center">
            {/* <MeltingButton link={"/products"} text={"VIEW ALL PRODUCTS"} /> */}
            {/* <Link to="/products">
              <Button>VIEW ALL PRODUCTS</Button>
            </Link> */}
            {data?.callToAction?.type === "link" && (
              <div className="d-flex justify-content-center">
                <Link
                  to={data?.link}
                  style={
                    {
                      // textDecoration: "underline",
                      // color: "#41655b",
                    }
                  }
                  className="mt-3 d-flex align-items-center justify-content-center btn"
                  onClick={() => handleCTA(data?.callToAction?.action)}
                >
                  {data?.callToAction?.text}
                </Link>
              </div>
            )}
          </div>
        </Row>
      )}
    </>
  )
}

export default ProductVariant

ProductVariant.propTypes = {
  data: PropTypes.any,
}
