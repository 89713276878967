module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "Grampoo",
  shortName: "Grampoo",
  siteMetadata: {
    websiteTitle: "Grampoo",
    description: "Grampoo e-commerce",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.87:4000",
  apiEndpointProd: "https://api.grampoo.com/",
  frontEndUrl: "https://grampoo.com/",
  tokenPrefix: "grampoo_token",
}
